body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f9f9f9; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hyperlink {
  color: #f5a623;
  text-decoration: none;
}

.copyrightText {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  color: #a1a1a1;
}

.header {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.myBubbleUI {
  width: 100%;
  margin: 0;
  background-color: white;
  /* position: absolute; */
  /* height: 500px; */
  /* max-width: 1000px; */
  max-height: 100vh;
  border-radius: 50px;
}

.bubbleContainer {
  background-size: cover;
  background-position: top;
  min-height: 100%;
  top: 0;
  /* height: 100%; */
  /* position: relative; */
}

.child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.charactersLeftText {
  font-size: 12px;
  margin-top: 8px;
  color: #a1a1a1;
}

.noContent {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.optionContainer {
  border-radius: 50%;
  /* display: flex; */
  /* height: 100%; */
  /* width: 100%; */
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: table;
  overflow: scroll;
}

.optionText {
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;

  /* using centers for the transform */
  /* transform-origin: center center; */
  /* calling calc() function for the calculation to move left and up the element from the center point */
  /* transform: translateX(calc((100%) * (-1)));
  transform: translateY(calc((100%) * (-1))); */
  /* padding: 10px; */
  /* align-self: center;
  text-align: center; */

  /* background-color: antiquewhite; */
  /* margin-top: 15px;
  margin-bottom: 10px; */
  /* margin-right: 5px;
  margin-left: 5px; */
  /* margin-top: 50px; */
  /* font-weight: bold; */
}
